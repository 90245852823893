import React, { createContext, useCallback, useEffect, useState } from "react";

// Create a Context
export const BackgroundActionsContext = createContext();

// Create a provider component
const LayoutBackgroundActions = ({ children }) => {
    const [expanded, setExpanded] = useState(false);
    const [filesProgressIndicator, setFilesProgressIndicator] = useState([
        // {
        //     file: FILE,
        //     streamId: STRING,
        //     status: ENUM("calculating", "processing", "success", "error"),
        //     percentage: NUMBER,
        //     secondsRemaining: NUMBER,
        // },
    ]);

    const updateFileProgressIndicator = (progress) => {
        setFilesProgressIndicator((prev) => {
            // Update Progress item
            if (prev.find((e) => e.streamId === progress.streamId))
                return prev.map((item) =>
                    item.streamId === progress.streamId
                        ? {
                              ...item,
                              ...progress,
                          }
                        : item
                );

            // Create Progress item
            setExpanded(true);
            return [...prev, progress];
        });
    };

    const cancelAction = useCallback(
        async (streamId) => {
            const streamTarget = filesProgressIndicator.find(
                (e) => e.streamId === streamId
            );
            if (streamTarget === -1) throw new Error("Stream ID not found");
            setFilesProgressIndicator((prev) =>
                prev.map((item) =>
                    item.streamId === streamId
                        ? {
                              ...item,
                              status: "cancelled",
                          }
                        : item
                )
            );
            return await streamTarget.abortStream();
        },
        [filesProgressIndicator]
    );

    const removeStream = useCallback(
        (streamId) => {
            setFilesProgressIndicator((prev) =>
                prev.filter((item) => item.streamId !== streamId)
            );
        },
        [filesProgressIndicator]
    );

    // Actions automatiques suivant les interactions avec le module
    useEffect(() => {
        if (filesProgressIndicator.length === 0) {
            setExpanded(false);
        }
    }, [filesProgressIndicator]);

    return (
        <BackgroundActionsContext.Provider
            value={{
                filesProgressIndicator,
                setFilesProgressIndicator,

                updateFileProgressIndicator,
                cancelAction,
                removeStream,

                expanded,
                setExpanded,
            }}
        >
            {children}
        </BackgroundActionsContext.Provider>
    );
};

export default LayoutBackgroundActions;
