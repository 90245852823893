import { useCallback, useEffect } from "react";
import { useLocation } from "react-router";

// Détecte si un élément est dans la partie haute / basse de l'écran
export function getElementScreenYPosition(element, defaultPos) {
    if (!element) return defaultPos;

    let posY = defaultPos;
    let elementPosY = element.getBoundingClientRect().top + window.scrollY;

    if (elementPosY < window.innerHeight / 2 + 100) posY = "bottom";
    else posY = "top";

    return posY;
}

// Détecte si un élément est dans la partie gauche / droite de son parent
export function getElementScreenXPositionInParent(childElement, parentElement) {
    const clickedRect = childElement.getBoundingClientRect();
    const parentRect = parentElement.getBoundingClientRect();

    const parentCenter = parentRect.left + parentRect.width / 2;

    const clickedCenter = clickedRect.left + clickedRect.width / 2;

    if (clickedCenter <= parentCenter) return "left";
    else return "right";
}

export const usePreventModalCloseOnBackButton = (
    isModalOpen,
    closeModal = () => {}
) => {
    const location = useLocation();
    useEffect(() => {
        if (isModalOpen) {
            // Add a dummy history state to detect back button press
            window.history.pushState(null, "", window.location.href);

            const handlePopState = () => {
                closeModal();
                // Push the state again to keep the modal open on further back presses
                window.history.pushState(null, "", window.location.href);
            };

            window.addEventListener("popstate", handlePopState);

            return () => {
                window.removeEventListener("popstate", handlePopState);
                // Remove the dummy state when modal is closed
                detectIfSamePage();
            };
        }
    }, [isModalOpen, closeModal]);

    const detectIfSamePage = useCallback(() => {
        if (window.location.pathname === location.pathname) closeModal();
    }, [location]);
};
