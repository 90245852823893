import { ConfigProvider, Spin, theme, Modal } from "antd";
import React from "react";
import frFR from "antd/locale/fr_FR";
import LayoutModal from "./LayoutModal";
import LayoutNotifications from "./LayoutPopup";
import LayoutDrawer from "./LayoutDrawer";
import MaterialIcons from "components/common/MaterialIcons";
import LayoutBackgroundActions from "./LayoutBackgroundActions";

Spin.setDefaultIndicator(
    // <Spinner isSmall widthClass=" w-6" heightClass=" h-6" />
    <MaterialIcons name="progress_activity" spin size="xl" isOutlined />
);

Modal.defaultProps = {
    width: 700,
    destroyOnClose: true,
    centered: true,
    okButtonProps: {
        autoFocus: true,
        htmlType: "submit",
    },
    cancelButtonProps: {
        variant: "outlined",
        size: "large",
    },
    styles: {
        content: {
            padding: 0,
        },
        header: {
            padding: "16px",
            borderBottom: "1px solid #dddddd",
        },
        body: {
            padding: "16px",
            maxHeight: "70vh",
            overflow: "auto",
        },
        footer: {
            padding: "10px 16px",
        },
    },
};

// ...
const AntdProvider = ({ children }) => {
    const { token } = theme.useToken();

    return (
        <ConfigProvider
            locale={frFR}
            theme={{
                cssVar: true,
                token: {
                    // INCASE : antd theme from json
                    // ...configTheme,
                    colorPrimary: "#0038cb",
                    colorInfo: "#0038cb",
                    colorSuccess: "#3ce1b9",
                    // colorWarning: "#fff500",
                    colorError: "#ff0047",
                    // colorBgBase: "#FAFBFD", // "#fafbfd",
                    colorBgLayout: "#eef2ff",
                    // colorTextBase: "#010527",
                    fontFamily: "Montserrat",
                    boxShadow: "3px 5px 9px 1px rgba(0,0,0,0.20)",
                    boxShadowSecondary: "3px 5px 9px 1px rgba(0,0,0,0.20)",
                    boxShadowTertiary: "3px 5px 9px 1px rgba(0,0,0,0.20)",
                },
                components: {
                    Button: {
                        // borderRadius: 2,
                        // borderRadiusLG: 4,
                        fontWeight: 700,
                    },
                    Badge: {
                        dotSize: "10px",
                    },
                    FloatButton: {
                        zIndex: 1,
                        zIndexBase: 1,
                        zIndexPopupBase: 1,
                    },
                    Card: {
                        colorBorderSecondary: token.colorBorder,
                        colorBgBase: "#ffffff",
                        borderRadiusLG: 12,
                        headerFontSize: 18,
                    },
                    Layout: {
                        // headerHeight: 80,
                        headerBg: "#eef2ff",
                        siderBg: "#eef2ff",
                        colorBgLayout: "#eef2ff",
                        headerPadding: `0 ${token.paddingLG / 2}px`,
                    },
                    Modal: {},
                },
            }}
        >
            <LayoutNotifications>
                <LayoutModal>
                    <LayoutDrawer>
                        <LayoutBackgroundActions>
                            {children}
                        </LayoutBackgroundActions>
                    </LayoutDrawer>
                </LayoutModal>
            </LayoutNotifications>
        </ConfigProvider>
    );
};

export default AntdProvider;
